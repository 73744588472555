"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeJwtPayload = void 0;
/**
 * Decode JWT token into a key/value payload.
 */
const decodeJwtPayload = (token) => {
    const [_rawHeaders, rawPayload, _rawSignature] = token.split('.');
    const payload = atob(rawPayload);
    return JSON.parse(payload);
};
exports.decodeJwtPayload = decodeJwtPayload;
