import { UserGuiding } from 'src/shims/userGuiding'
import { useUserGuidingStore } from 'src/store/userGuiding'

const DEFAULT_CHECKLIST_ID = '18248'

const withUserGuiding = (callback: (userGuiding: UserGuiding) => void) => {
  return () => {
    if (!window.userGuiding) return

    return callback(window.userGuiding)
  }
}

export const useUserGuiding = () => {
  const store = useUserGuidingStore()

  const updateChecklistPercentage = () => {
    const info = window.userGuiding?.getChecklistCompletionInfo()
    if (!info) {
      console.debug('No checklist info available')
      return
    }

    const checklistId = store.activeChecklistId || DEFAULT_CHECKLIST_ID
    const checklist = info.find((c) => c.id.toString() === checklistId)
    if (!checklist) {
      console.debug(`No checklist found for ID ${checklistId}`)
      return
    }

    const totalItems = checklist.items.length
    const completedItems = checklist.completedItems.length
    const percent = (completedItems / totalItems) * 100
    store.setActiveChecklistCompletionPercent?.(percent)
  }

  /**
   * https://panel.userguiding.com/assistants/settings/content/18248
   */
  const launchGettingStartedChecklist = withUserGuiding((userGuiding) => {
    const checklistId = DEFAULT_CHECKLIST_ID
    userGuiding.launchChecklist(checklistId, {
      itemsShown: true,
    })
    userGuiding.expandChecklist(checklistId)
    store.setActiveChecklistId(checklistId)
    updateChecklistPercentage()
  })

  const openResourceCenter = (id: number) =>
    withUserGuiding((userGuiding) => {
      if (store.activeResourceCenterId === id) {
        console.log('expandResourceCenter', id)
        userGuiding.expandResourceCenter(id)
      } else {
        console.log('launchResourceCenter', id)
        store.setActiveResourceCenterId(id)
        userGuiding.launchResourceCenter(id, {
          sidebarOpen: true,
          bypassFilters: true,
        })
        userGuiding.expandResourceCenter(id)
      }
    })()

  /**
   * https://panel.userguiding.com/resourcecenter/settings/content/3804
   */
  const openDefaultResourceCenter = () => {
    openResourceCenter(2317)
  }

  /**
   * https://panel.userguiding.com/resourcecenter/settings/content/3804
   */
  const openProductUpdatesResourceCenter = () => {
    openResourceCenter(3804)
  }

  return {
    ...store,
    launchGettingStartedChecklist,
    openDefaultResourceCenter,
    openProductUpdatesResourceCenter,
    updateChecklistPercentage,
  }
}
