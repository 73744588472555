import { create } from 'zustand'

import { ResourceCenterId, UserGuidingIdentity } from 'src/shims/userGuiding'

interface UserGuidingStore {
  loaded: boolean
  registerAsLoaded: () => void

  identity?: [string, UserGuidingIdentity]
  setIdentity: (staffId: string, data: UserGuidingIdentity) => void

  activeChecklistId?: string
  setActiveChecklistId: (checklistId: string) => void

  activeChecklistCompletionPercent?: number
  setActiveChecklistCompletionPercent?: (percent: number) => void

  activeResourceCenterId?: ResourceCenterId
  setActiveResourceCenterId: (resourceCenterId: ResourceCenterId) => void
}

export const useUserGuidingStore = create<UserGuidingStore>()((set) => ({
  loaded: false,
  registerAsLoaded: () => set({ loaded: true }),
  setIdentity: (staffId, data) => {
    set({ identity: [staffId, data] })
    if (window.userGuiding) {
      window.userGuiding.identify(staffId, data)
    }
  },
  setActiveChecklistId: (checklistId) =>
    set({ activeChecklistId: checklistId }),
  setActiveResourceCenterId: (resourceCenterId) =>
    set({ activeResourceCenterId: resourceCenterId }),
  setActiveChecklistCompletionPercent(percent) {
    set({ activeChecklistCompletionPercent: percent })
  },
}))
