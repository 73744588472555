import Bugsnag from '@bugsnag/js'
import { useRouter } from 'next/router'

import { useCurrentUserPeriodicalQuery } from 'src/hooks/useCurrentUser'
import { useCurrentBusinessQuery } from 'src/types/graphql'

/**
 * Shortcut to get the current business ID.
 *
 * If undefined then the data is still loading and we do not know if the ID is valid yet.
 * If null then the ID is invalid and not found in the list of valid businesses.
 */
export const useCurrentBusinessId = (): string | undefined | null => {
  const currentBusinessAlias = useCurrentBusinessRouteAlias()
  const { data, loading, isAuthenticated } = useCurrentUserPeriodicalQuery()

  if (!isAuthenticated) return null
  if (!data || loading) return undefined

  const allBusinesses =
    data?.currentUser?.staffs
      ?.map((staff) => staff.business)
      .flatMap((business) => business) || []

  // Attempt to find the business ID from the alias
  const businessIdFromAlias =
    allBusinesses.find((business) => business.alias === currentBusinessAlias)
      ?.id || null
  if (businessIdFromAlias !== null) {
    return businessIdFromAlias
  }

  // Fall back to lookup by ID then redirect the user.
  // We mainly do this to catch bad links to update our systems.
  const businessIdFromId =
    allBusinesses.find((business) => business.id === currentBusinessAlias)
      ?.id || null
  if (businessIdFromId !== null) {
    const businessAlias = allBusinesses.find(
      (business) => business.id === businessIdFromId,
    )?.alias
    if (businessAlias) {
      Bugsnag.notify(
        new Error('Using Business ID in URL is deprecated.'),
        (event) => {
          event.groupingHash = `business-id-in-url-deprecated:${businessIdFromId}`
          event.severity = 'info'
          event.addMetadata('business', {
            id: businessIdFromId,
            alias: businessAlias,
          })
        },
      )
      window.location.href = window.location.href.replace(
        `/businesses/${businessIdFromId}/`,
        `/businesses/${businessAlias}/`,
      )
    }
    return null
  }

  return null
}

/**
 * Get the business alias from the current route.
 * This may actually by an ID or an alias, depending on the route. We must support both.
 * Returns null if we are not within a business context.
 */
export const useCurrentBusinessRouteAlias = (): string | null => {
  const router = useRouter()
  const businessAlias =
    router.query.businessId?.toString() || getCurrentBusinessRouteAlias()

  return businessAlias || null
}

/**
 * Parse the current route to get the business alias.
 * This may by an ID or an alias, so we assume alias and let the API figure it out.
 */
export const getCurrentBusinessRouteAlias = () => {
  const path = new URL(window.location.href).pathname
  if (path.startsWith('/admin')) {
    return ''
  }
  const re = /\/businesses\/(.*?)(\/|$)/
  const matches = re.exec(path)
  if (!matches) {
    return ''
  }
  return matches[1]
}

/**
 * Get the business for the current context.
 */
export const useCurrentBusiness = () => {
  const currentBusinessId = useCurrentBusinessId()

  const { data, loading, error, refetch } = useCurrentBusinessQuery({
    variables: {
      businessId: currentBusinessId || '',
    },
    fetchPolicy: 'cache-first',
    pollInterval: 5 * 60 * 1000, // Refresh data if page is left open
    skip: !currentBusinessId,
  })

  return {
    currentBusinessId,
    currentBusiness: data?.currentBusiness,
    loading,
    error,
    refetch,
  }
}
