import { useApiConfig } from '@upvio/next'
import React, { CSSProperties } from 'react'

const GTMNoscriptTag: React.FC = () => {
  const config = useApiConfig()
  if (!config.gtm_id) return null

  const src = `https://www.googletagmanager.com/ns.html?id=${config.gtm_id}`
  const styles: CSSProperties = { display: 'none', visibility: 'hidden' }

  return (
    <noscript>
      <iframe
        src={src}
        height='0'
        width='0'
        style={styles}
        title='Google Tag Manager'
      />
    </noscript>
  )
}

export default GTMNoscriptTag
