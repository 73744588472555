import Bugsnag from '@bugsnag/js'

export const bugsnagStart = () => {
  if (
    !process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ||
    !process.env.NEXT_PUBLIC_BUGSNAG_ENABLED
  ) {
    return
  }

  if (process.env.NEXT_PUBLIC_BUGSNAG_ENABLED.toString() === 'false') {
    return
  }

  if (process.env.NEXT_PHASE !== 'phase-production-build') {
    // next.js executes top-level code at build time. See https://github.com/vercel/next.js/discussions/16840 for further example
    // So use NEXT_PHASE to avoid Bugsnag.start being executed during the build phase
    // See https://nextjs.org/docs/api-reference/next.config.js/introduction and https://github.com/vercel/next.js/blob/canary/packages/next/shared/lib/constants.ts#L1-L5 for
    // more details on NEXT_PHASE
    if (process.env.NEXT_IS_SERVER === 'true') {
      Bugsnag.start({
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
        appVersion: process.env.VERCEL_GIT_COMMIT_SHA,
        releaseStage: process.env.NEXT_PUBLIC_APP_ENV,
      })
    } else {
      // If preferred two separate Bugsnag projects e.g. a javascript and a node project could be used rather than a single one
      Bugsnag.start({
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
        appVersion: process.env.VERCEL_GIT_COMMIT_SHA,
        plugins: [],
        releaseStage: process.env.NEXT_PUBLIC_APP_ENV,
      })
    }
  }
}
