import { ClientOnly, useApiConfig } from '@upvio/next'
import {
  ErrorView,
  Provider as AustralisProvider,
  ToastGlobalProvider,
} from 'australis'
import { ErrorBoundary } from 'next/dist/client/components/error-boundary'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import { GTMAppTag } from 'src/components/GoogleTagManager'
import LoadingBar from 'src/components/Loading/LoadingBar'
import { APP_ENV } from 'src/utils/constants'

const TelehealthClientLoader = dynamic(
  () => import('src/blocks/Zoom/components/ClientLoader'),
  {
    ssr: false,
  },
)

interface Props {
  children: ReactNode
}

export const TelehealthPatientLayout: React.FC<Props> = ({ children }) => {
  const config = useApiConfig()
  const router = useRouter()

  return (
    <ClientOnly>
      <Head>
        <title>{config.brandName} - Telehealth</title>
        <meta name='robots' content='noindex, nofollow' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, interactive-widget=resizes-content, viewport-fit=cover'
        />
        <link rel='icon' href={`/${config.brand}.favicon.png`} />
      </Head>
      <ErrorBoundary
        errorComponent={(props) => <ErrorView {...props} env={APP_ENV} />}
      >
        <GTMAppTag />
        <LoadingBar />
        <AustralisProvider navigate={router.push}>
          <div style={{ height: '100dvh' }}>{children}</div>
        </AustralisProvider>
        <TelehealthClientLoader />
        <ToastGlobalProvider />
      </ErrorBoundary>
    </ClientOnly>
  )
}
