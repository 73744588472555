import { AuthPayload } from '../types/graphql'

import { trackGTAGEvent } from './gtag'

export const trackSigninEvent = (auth: AuthPayload) => {
  const data = {
    userId: auth.user.id,
    // authenticationMethod: auth.user.identityProvider ?? '',
  }

  if (auth.userIsNew) {
    trackGTAGEvent('signup', data)
  } else {
    trackGTAGEvent('signin', data)
  }
}
