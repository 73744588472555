import {
  ApolloClient,
  createHttpLink,
  DocumentTransform,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { ToastQueueList } from 'australis'
import { Kind, visit } from 'graphql'
import { Logger } from 'src/hooks/useLogger'
import { IS_SERVER } from 'src/utils/constants'
import { getCurrentBusinessRouteAlias } from './hooks/useCurrentBusiness'
import { useAuthStore } from './store/authStore'
import { isUnauthorizedError } from './utils/auth'

const enableDebugDbMetrics = IS_SERVER
  ? false
  : window.localStorage.getItem('debug.dbMetrics') === '1'

export const getApiDomain = (): string => {
  return window.location.hostname.replace('account.', 'api.')
}

const httpLink = createHttpLink({
  uri: () => {
    return `https://${getApiDomain()}`
  },
  credentials: 'include',
})

/**
 * Add db metrics field to every query for debugging
 */
const documentTransform = new DocumentTransform((document) => {
  if (!enableDebugDbMetrics) {
    return document
  }

  // Determine the operation name
  const operationDefinitions = visit(document, {
    OperationDefinition: {
      enter(node) {
        return node.name?.value
      },
    },
  })
  const primaryOperationName = (operationDefinitions.definitions[0] ||
    null) as unknown as string | null
  if (!primaryOperationName) {
    return document
  }

  console.debug('documentTransform()', primaryOperationName)
  const transformedDocument: typeof document = {
    ...document,
    definitions: document.definitions.map((definition) => {
      if (definition.kind !== 'OperationDefinition') {
        return definition
      }

      return {
        ...definition,
        selectionSet: {
          ...definition.selectionSet,
          selections: [
            {
              kind: Kind.FIELD,
              name: {
                kind: Kind.NAME,
                value: '_dbQueryCount',
              },
              arguments: [],
              directives: [],
            },
            {
              kind: Kind.FIELD,
              name: {
                kind: Kind.NAME,
                value: '_dbQueryMetrics',
              },
              arguments: [],
              directives: [],
            },
            ...definition.selectionSet.selections,
          ],
        },
      }
    }),
  }
  return transformedDocument
})

const authLink = setContext((request, { headers }) => {
  const { accessToken } = useAuthStore.getState()
  const businessAlias = getCurrentBusinessRouteAlias()

  headers = headers || {}
  if (!headers.Authorization && accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`
  }
  if (businessAlias && request.operationName !== 'currentUser') {
    headers['X-Business-Alias'] = businessAlias
  }

  return {
    headers: {
      ...headers,
    },
  }
})

/* Handles all GraphQL operations errors */
const errorLink = onError((error) => {
  const inSignInPage = window.location.pathname.indexOf('/auth') === 0
  if (isUnauthorizedError(error.graphQLErrors) && !inSignInPage) {
    window.location.href = '/auth/signin?redirectUrl=' + window.location.href
    const logger = new Logger()
    logger.debug(() => [
      'Unauthorized GraphQL error. Redirecting to signin page.',
      error,
    ])
    return
  }

  error.graphQLErrors?.forEach((graphQLError) => {
    ToastQueueList.add({
      title: graphQLError.message,
      status: 'error',
    })
  })
})

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  defaultOptions: {
    query: { errorPolicy: 'all' },
    mutate: { errorPolicy: 'all' },
  },
  cache: new InMemoryCache({
    typePolicies: {
      Business: {
        fields: {
          usage: {
            keyArgs: false,
          },
        },
      },
    },
  }),
  documentTransform,
})

export default client
