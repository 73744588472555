import { useRouter } from 'next/router'
import { useEffect } from 'react'

import Loading from 'src/components/Loading/Loading'
import { useAuth } from 'src/hooks/useAuth'

interface Props {
  children: React.ReactNode
}

/**
 * All pages requiring a logged in user should be wrapped in this.
 */
const WithAuthentication: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const currentPath = router.asPath
  const { isAuthenticated, login } = useAuth()

  let canSkip = false
  if (currentPath.indexOf('/auth/') === 0) {
    canSkip = true
  }
  if (currentPath.indexOf('/api/') === 0) {
    canSkip = true
  }
  if (currentPath.indexOf('/telehealth/') === 0) {
    canSkip = true
  }
  const shouldTriggerAuth = !isAuthenticated && !canSkip

  useEffect(() => {
    if (shouldTriggerAuth) {
      login({
        redirectUrl: window.location.href,
      })
    }
  }, [shouldTriggerAuth, login])

  if (shouldTriggerAuth) {
    return <Loading fullScreen />
  }

  return <>{children}</>
}

export default WithAuthentication
