import { useApiConfig } from '@upvio/next'
import { useAuthStore } from 'src/store/authStore'
import { accessTokenIsExpired } from 'src/utils/auth'

interface SignupOptions {
  inviteKey?: string
  email?: string
}

interface LoginOptions {
  redirectUrl?: string
}

interface AuthCallbackState {
  redirectUrl?: string
}

interface UseAuthResult {
  isAuthenticated: boolean
  login: (options?: LoginOptions) => void
  logout: () => void
  signup: (options?: SignupOptions) => void
}
export const useAuth = (): UseAuthResult => {
  const {
    accessToken,
    currentUser,
    clearStorage: clearAuthTokens,
  } = useAuthStore()
  const config = useApiConfig()

  const isAuthenticated =
    typeof accessToken === 'string' &&
    !accessTokenIsExpired(accessToken) &&
    !!currentUser

  const login = (options?: LoginOptions) => {
    const stateId = Math.random().toString(36).substring(2, 15)
    const stateData: AuthCallbackState = {}
    if (options?.redirectUrl) {
      stateData.redirectUrl = options.redirectUrl
    }
    const stateString = JSON.stringify(stateData)
    sessionStorage.setItem(stateId, stateString)

    const auth0Url = `https://auth.upvio.com/authorize?response_type=code&client_id=${config.auth0_web_client_id}&redirect_uri=https://${window.location.hostname}/auth/callback&state=${stateId}&audience=${config.auth0_api_audience}&scope=openid%20profile%20email`
    location.href = auth0Url
  }

  const signup = (options?: SignupOptions) => {
    const stateId = Math.random().toString(36).substring(2, 15)
    const stateData: AuthCallbackState = {}
    const stateString = JSON.stringify(stateData)
    sessionStorage.setItem(stateId, stateString)

    const auth0BaseUrl = `https://auth.upvio.com/authorize?response_type=code&client_id=${config.auth0_web_client_id}&`
    const auth0options = [
      `redirect_uri=https://${window.location.hostname}/auth/callback`,
      `state=${stateId}`,
      `audience=${config.auth0_api_audience}`,
      'scope=openid%20profile%20email',
      'screen_hint=signup',
      'prompt=login',
    ]
    if (options?.inviteKey) {
      auth0options.push(`ext-inviteKey=${options.inviteKey}`)
    }
    if (options?.email) {
      auth0options.push(`login_hint=${encodeURIComponent(options.email)}`)
    }
    const href = `${auth0BaseUrl}${auth0options.join('&')}`
    location.href = href
  }

  const logout = () => {
    // Clear persisted tokens in localstorage
    // This logs the user out from the platform, but not from Auth0
    clearAuthTokens()

    // Log the user out in Auth0
    window.location.href = `https://${config.auth0_domain}/v2/logout?returnTo=${window.location.origin}`
  }

  return {
    isAuthenticated,
    login,
    logout,
    signup,
  }
}
