import { useRouter } from 'next/router'

import Loading from 'src/components/Loading/Loading'
import { useCurrentUserPeriodicalQuery } from 'src/hooks/useCurrentUser'
import { useLogger } from 'src/hooks/useLogger'
import WithAuthentication from 'src/layout/WithAuthentication/WithAuthentication'
import { StaffRole } from 'src/types/graphql'

import type { NextPage } from 'next'

type CurrentUser = NonNullable<
  ReturnType<typeof useCurrentUserPeriodicalQuery>['data']
>['currentUser']

type UserBusiness = NonNullable<CurrentUser>['staffs'][number]['business']

/**
 * Home page of the application.
 * - Redirects to onboarding if user is not a member of any business.
 * - Redirects to business calendar if user is a member of only one business.
 * - Redirects to business list if user is a member of more than one business.
 */
const Home: NextPage = () => {
  const router = useRouter()
  const logger = useLogger()

  useCurrentUserPeriodicalQuery({
    onCompleted: (data) => {
      if (!data) return

      const businesses: UserBusiness[] = []
      const staffs = data.currentUser?.staffs || []
      for (const staff of staffs) {
        businesses.push(staff.business)
      }
      logger.debug(() => `User is member of ${businesses.length} businesses`)

      // Send user to create their first business
      if (businesses.length === 0) {
        return router.push('/onboarding')
      }

      // If a user has a single business, send them directly to that business in a specific flow
      if (businesses.length === 1) {
        const business = businesses[0]
        const availableFeatures = business.availableFeatures || {}

        if (
          !business.onboardingCompletedAt &&
          business.staffRoles.includes(StaffRole.Owner) &&
          availableFeatures.onboarding
        ) {
          return router.push(`/businesses/${business.alias}/onboarding`)
        }

        const staff = staffs[0]
        if (!staff.onboardingCompletedAt && availableFeatures.onboarding) {
          return router.push(`/businesses/${business.alias}/onboarding`)
        }

        return router.push(`/businesses/${business.alias}/calendar`)
      }

      router.push('/businesses')
    },
  })

  return <Loading fullScreen />
}

export default Home
