import { useAuth } from 'src/hooks/useAuth'
import { useCurrentUserQuery } from 'src/types/graphql'

type CurrentUserQueryOptions = Pick<
  NonNullable<Parameters<typeof useCurrentUserQuery>[0]>,
  'onCompleted'
>

/**
 * Load the currently authenticated user from the server.
 * This will periodically poll the server for updates and refresh all components.
 */
export const useCurrentUserPeriodicalQuery = (
  options?: CurrentUserQueryOptions,
) => {
  const { isAuthenticated } = useAuth()

  const currentUserQuery = useCurrentUserQuery({
    fetchPolicy: 'cache-first',
    pollInterval: 5 * 60 * 1000,
    skip: !isAuthenticated,
    ...(options || {}),
  })

  return {
    ...currentUserQuery,
    isAuthenticated,
  }
}

/**
 * Retrieve the currently logged in user.
 *
 * undefined means the user has not been fetched yet.
 * null means the user is not logged in.
 */
export const useCurrentUser = () => {
  const { data, loading, isAuthenticated } = useCurrentUserPeriodicalQuery()

  if (!isAuthenticated) {
    return null
  }

  if (loading) {
    return undefined
  }

  return data?.currentUser || null
}
