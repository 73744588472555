import { useEffect, useState } from 'react'

import client from 'src/apollo-client'
import Loading from 'src/components/Loading/Loading'
import { useCurrentBusinessId } from 'src/hooks/useCurrentBusiness'
import { useLogger } from 'src/hooks/useLogger'

/**
 * Since we do not use businessId in the variables of all queries,
 * Apollo tries to use its cache to display the data when switching between businesses.
 * If the businessId changes then we want to reset the cache data to avoid
 * showing the wrong data temporarily.
 */
const ApolloCacheResetter = () => {
  const logger = useLogger()
  const currentBusinessId = useCurrentBusinessId()
  const [businessId, setBusinessId] = useState<string | null | undefined>(
    undefined,
  )
  const [resetting, setResetting] = useState(false)

  useEffect(() => {
    if (businessId === currentBusinessId) return
    if (currentBusinessId === undefined) return
    setBusinessId(currentBusinessId)

    logger.debug(
      () => `Business ID changed from ${businessId} to ${currentBusinessId}`,
    )

    // Initial value is set once value goes from undefined to a valid value
    if (businessId === undefined) return

    // If going from no business ID to a value then we do not need to clear
    // the cache since this is the first time we have a valid business ID.
    if (businessId === null) return

    // We add a slight delay here to avoid Apollo from refiring
    // requests with the old ID. If this happens the variable value
    // and the header value do not match and a 401 is thrown which
    // logs out the user.
    logger.debug(() => 'Clearing Apollo Client cache')
    setResetting(true)
    setTimeout(() => {
      client.resetStore().then(() => {
        setResetting(false)
      })
    }, 100)
  }, [businessId, currentBusinessId, setBusinessId, logger])

  return resetting ? <Loading fullScreen overlay /> : null
}

export default ApolloCacheResetter
