import { useApiConfig } from '@upvio/next'
import Script from 'next/script'
import React, { useEffect } from 'react'

import { useUserGuiding } from 'src/hooks/useUserGuiding'

// Set this as early as possible (when file is required) to avoid tag manager loading a duplicate tag
if (typeof window !== 'undefined') {
  window.__usingIntegratedUserGuiding = true
}

const UserGuidingLoader: React.FC = () => {
  const config = useApiConfig()
  const { loaded, registerAsLoaded, updateChecklistPercentage } =
    useUserGuiding()

  const script = `(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','${config.user_guiding_id}');`

  useEffect(() => {
    if (!window.userGuidingLayer) window.userGuidingLayer = []
    if (loaded) return

    window.userGuidingLayer.push({
      event: 'onload',
      fn: () => {
        console.debug('UserGuiding script loaded')
        registerAsLoaded()
      },
    })
    window.userGuidingLayer.push({
      event: 'onIdentificationComplete',
      fn: () => {
        console.debug('UserGuiding identification complete')
        updateChecklistPercentage()
      },
    })
  }, [loaded, registerAsLoaded, updateChecklistPercentage])

  if (!config.user_guiding_id) return null

  return (
    <Script
      id='user-guiding-loader'
      dangerouslySetInnerHTML={{ __html: script }}
    />
  )
}

export default UserGuidingLoader
