import { useApiConfig } from '@upvio/next'
import Script from 'next/script'
import React from 'react'

const GTMAppTag: React.FC = () => {
  const config = useApiConfig()
  if (!config.gtm_id) return null

  const script = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${config.gtm_id}');
  `
  return (
    <Script
      id='google-tag-manager'
      dangerouslySetInnerHTML={{ __html: script }}
    />
  )
}

export default GTMAppTag
