import { Spinner } from 'australis'

import clsx from 'clsx'
import styles from './styles.module.scss'

interface Props {
  children?: React.ReactNode
  className?: string
  fullScreen?: boolean
  overlay?: boolean
}

const Loading: React.FC<Props> = ({
  children,
  className = '',
  fullScreen,
}: Props) => {
  const classNames = clsx(styles.Loading, className, {
    [styles.fullScreen]: fullScreen,
    [styles.overlay]: fullScreen,
  })
  return (
    <div className={classNames}>
      <Spinner />
      {children}
    </div>
  )
}

export default Loading
