import { useMemo } from 'react'
import { LSK_LOG_LEVEL } from 'src/utils/constants'

export enum LogLevel {
  Debug = 0,
  Info = 1,
  Warn = 2,
  Error = 3,
}

export class Logger {
  level: LogLevel

  constructor() {
    this.level = parseInt(localStorage.getItem(LSK_LOG_LEVEL) || '1', 10)
  }

  debug(callback: () => string | any[]) {
    if (this.level > LogLevel.Debug) return

    this.log('debug', callback)
  }

  info(callback: () => string | any[]) {
    if (this.level > LogLevel.Info) return

    this.log('info', callback)
  }

  warn(callback: () => string | any[]) {
    if (this.level > LogLevel.Warn) return

    this.log('warn', callback)
  }

  error(callback: () => string | any[]) {
    if (this.level > LogLevel.Error) return

    this.log('error', callback)
  }

  log(
    level: 'debug' | 'info' | 'warn' | 'error',
    callback: () => string | any[],
  ) {
    const result = callback()
    if (Array.isArray(result)) {
      console[level]('🔼', ...result)
    } else {
      console[level]('🔼', result)
    }
  }
}

export const useLogger = () => {
  return useMemo(() => new Logger(), [])
}
