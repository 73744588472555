import { Router } from 'next/router'
import { useEffect, useState } from 'react'
import styles from './styles.module.scss'

const LoadingBar = () => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const start = () => {
      setLoading(true)
    }
    const end = () => {
      setLoading(false)
    }
    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [])

  return (
    <div
      className={[
        styles.LoadingBar,
        loading ? styles.LoadingBar_Loading : '',
      ].join(' ')}
    >
      <div
        className={[
          styles.LoadingBar_Progress,
          loading ? styles.LoadingBar_Progress_Loading : '',
        ].join(' ')}
      >
        &nbsp;
      </div>
    </div>
  )
}

export default LoadingBar
