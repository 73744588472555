interface EventData {
  [key: string]: string
}

/**
 * TODO: Move this to GTM hook
 * @deprecated
 */
export const trackGTAGEvent = (name: string, data: EventData) => {
  if (!window) {
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: name,
    ...data,
  })
}
